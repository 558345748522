import React from 'react';
import {Switch, Route} from 'react-router'
import LabsList from "./components/LabsList";


function LabsRouter () {
    return (
        <Switch>
            {/*<Route exact name="labs" path="/labs/page/:page" component={ProjectsList}/>*/}
            <Route exact name="labs" path="/labs/:id?" component={LabsList}/>
        </Switch>
    );
}

LabsRouter.propTypes = {};
LabsRouter.defaultProps = {};

export default LabsRouter;
