import ReactGA from 'react-ga'
import config from '../../config/config'

const isDev = () => {
  return window.location.host === 'localhost:3005'
}

export const initGA = () => {
  ReactGA.initialize(config.googleAnalyticsKey)
}

export const emitGAPageView = ({title, url}) => {
  if (isDev()) return
  const path = new URL(url).pathname
  ReactGA.pageview(path, undefined, title)
}

export const emitGAEvent = (args) => {
  // if (appStatus.development) return
  // ReactGA.event(args)
}
