import React from 'react';
import {Switch, Route} from 'react-router'
import ProjectsScreen from "./components/ProjectsScreen";
import './projects.scss'


function ProjectsRouter () {
    return (
        <Switch>
            {/*<Route exact name="projects" path="/projects/page/:page" component={ProjectsScreen}/>*/}
            <Route exact name="projects" path="/projects/:id?" component={ProjectsScreen}/>
        </Switch>
    );
}

ProjectsRouter.propTypes = {};
ProjectsRouter.defaultProps = {};

export default ProjectsRouter;
