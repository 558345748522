import {useState} from 'react'


const useLayer = (component = null) => {
  const [state, setState] = useState({
    layer: component,
    offset: 0
  })
  const setLayer = (content, offset ) => {
    setState((state) => {
      return {
        layer: content,
        offset: typeof offset === 'number' ? offset : state.offset
      }
    })
  }
  const {layer, offset} = state
  return [layer, setLayer, offset]
}

export default useLayer