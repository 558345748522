import React  from 'react'

const CssWaterfall = ({ children, delay = 200 }) => {
  return React.Children.map(children, (child, i) => {

    return {
      ...child,
      props: {...child.props, style: {
          ...child.props.style,
          animationDelay: (i * delay) + 'ms'
        }}
    }
  })
}

export default CssWaterfall
