import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import './page.scss'
import Layer from '../layer/Layer'
import scrollScreen from '../application/scrollScreen'


const Page = ({className, children, layer, onCloseLayer, layerTitle, style, offset}) => {
    const sectionRef = useRef()
    const items = React.Children.map(children, child => React.cloneElement(child, {sectionRef}))


    useEffect(() => {
        if (!layer) {
            console.log(offset)
            scrollScreen(offset, 0)
        }
    }, [offset, layer])


    return (
        <div id="container">
            <Layer title={layerTitle} open={!!layer} close={onCloseLayer} children={layer}/>
            <section style={style} ref={sectionRef} className={className}>
                {items}
            </section>
        </div>
    )
}


Page.propTypes = {
    style: PropTypes.object,
    header: PropTypes.node,
    className: PropTypes.string,
    layer: PropTypes.any
}
Page.defaultProps = {
    style: {},
    className: 'animated fadeIn',
    layer: null
}

export default Page
