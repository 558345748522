import React from 'react'
import CalloutComponent from '../../layout/error/CalloutComponent'
import Loader from '../../layout/loader/Loader'
function withARCLoader(Wrapped) {
  return (originalProps) => {
    const { loaded, error, arc } = originalProps
    // is an arc hook
    if (arc && !arc.arc.hasRequiredParams(originalProps)) {
      return <Wrapped {...originalProps} />
    }
    if (error) return <CalloutComponent error={error} />
    if (!loaded) return <Loader />
    return <Wrapped {...originalProps} />
  }
}

withARCLoader.propTypes = {}
withARCLoader.defaultProps = {}

export default withARCLoader
