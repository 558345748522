import React from 'react'
import {Helmet} from 'react-helmet'
import {getPageMetas} from '../../commons'
import {emitGAPageView} from '../analytics/ga'



const setMetasTags = (meta) => {
  ['title','description','image','url'].forEach(item => {
    const met = window.document.head.querySelector(`meta[property="og:${item}"]`)
    met.content = meta[item]
  })
  window.document.head.title = meta.title
  window.document.head.querySelector(`meta[name="description"]`).content = meta.description
  window.document.head.querySelector(`link[rel="canonical"]`).href = meta.url
}
//
// const OgGenerator = ({meta}) => {
//   useEffect(() => {
//     ['title','description','image','url'].forEach(item => {
//       const met = document.head.querySelector(`meta[property="og:${item}"]`)
//       met.content = meta[item]
//     })
//   }, [])
//   return <React.Fragment/>
// }

const Casque = ({title = '', description = '', url = '', image = ''}) => {
  const meta = getPageMetas({title, description, url, image})
  return (
    <React.Fragment>
      {/*<OgGenerator meta={meta} />*/}
      <Helmet onChangeClientState={(args) => {
        setMetasTags(meta)
        emitGAPageView(meta)
      }}>
        <link rel="canonical" href={meta.url} />
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
    </React.Fragment>

  )
}

{/*<meta property="og:title" content={meta.title} />*/}
{/*<meta property="og:description" content={meta.description} />*/}
{/*<meta property="og:image" content={'12'} />*/}
{/*<meta property="og:url" content={meta.url} />*/}


export default Casque
