import React from 'react'

import navigate from "../singletons/navigate"
import {Home as HomeIcon, Keyboard as KeyboardIcon} from '@material-ui/icons'
import Button from '@material-ui/core/Button'



function FatButton(props) {

    return (<Button className="fat-button" onClick={()=>navigate(props.to)}>
        <div className="icon">
            {props.icon}
        </div>
        <div className="title">{props.title}</div>
    </Button>)
}

export function SidebarMenu() {

    return (<div>

        <FatButton title="Home" to="/products" icon={<HomeIcon/>}/>
        <FatButton title="Kdaz" to="/history" icon={<KeyboardIcon/>}/>


    </div>)

}

export default SidebarMenu
