
export const getScrollOffset = () => {

  return {
    top: window.scrollY,
    left: window.scrollX
  }
}

const scrollScreen = (top, left) => {
  const section = window.document.querySelector('html')
  //console.log(section)
  section.scrollTo({
    top,
    left,
    //behavior: 'smooth'
  });
}
export default scrollScreen
