import {applyMiddleware, createStore, compose, combineReducers} from "redux"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"
import { connectRouter, routerMiddleware } from 'connected-react-router'
import history from "../singletons/history"
import {mixerStore} from 'react-arc'
import projectConfig from "../components/projects/projectsConfig";
import labsConfig from "../components/labs/labsConfig";
import tagsConfig from "../components/about/tagsConfig";
import device from '../layout/device/reducers/device'

const middleware = applyMiddleware(promise(), thunk, routerMiddleware(history))
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(middleware)



const reducers = {
    router: connectRouter(history),
    device: device,
    projects: mixerStore({config: projectConfig}),
    labs: mixerStore({config: labsConfig}),
    tags: mixerStore({config: tagsConfig})
}

const store = Object.keys(reducers).length > 0 ? createStore(combineReducers(reducers), enhancer) : createStore(enhancer)

export default store