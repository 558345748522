import React from 'react'
import './error.scss'
import PropTypes from 'prop-types'

const defaultTitle = "Error";
const defaultMessage = "Something is not working";


export function isNotFound(error) {
  return error !== null
    && typeof error === "object"
    && typeof error.response === "object"
    && error.response.status === 404;
}


class CalloutComponent extends React.Component {
  render() {
    const { error, source, title, children, style } = this.props;
    if (source) console.log("%c" + source, "color: #5e3dc2; font-weight: bold;");
    if (error) console.log(error);
    const notFound = isNotFound(error);
    const _title = title || defaultTitle;
    const extText = error && error.response && error.response.data && typeof error.response.data === 'string' ? error.response.data : ''

    return (<div className="callout callout-warning" style={style || {}}>
      <h4>{notFound ? "Not found" : _title}</h4>
      <p>{typeof error === "string" ? error : defaultMessage}</p>
      {extText ? <p>{extText}</p> : null}
      {children}
    </div>);
  }
}

CalloutComponent.propTypes = {
  error: PropTypes.any,
  title: PropTypes.string,
  style: PropTypes.object,
  source: PropTypes.string
};

export default CalloutComponent;