import React, {useEffect, useState} from 'react'
import LoadableImage from '../../../layout/image/LoadableImage'



const getImageInfo = ({image, parent}) => {

  // console.log(parent.clientWidth, parent.clientHeight)
  // console.log(image.naturalWidth,image.naturalHeight)
  const imageWidth = image.naturalWidth
  const imageHeight = image.naturalHeight
  const parentWidth = parent.clientWidth
  const parentHeight = parent.clientHeight
  const orientation = imageWidth > imageHeight ? 'landscape' : 'portrait'
  let scale = 1
  if (imageWidth > 0 && imageHeight > 0) {
    scale = Math.max(parentWidth / imageWidth, parentHeight / imageHeight)
  }


  return {
    orientation,
    scale,
    image: {
      image,
      width: imageWidth,
      height: imageHeight
    },
    parent: {
      parent,
      width: parentWidth,
      height: parentHeight
    },
    fill: {
      width: imageWidth * scale,
      height: imageHeight * scale
    }
  }

}

const CenteredThumb = ({src, parentRef, className = 'animated fadeIn', style, onLoad}) => {
  const [imageRef, setImageRef] = useState(null)
  const [info, setInfo] = useState(null)
  useEffect(() => {
    if(!imageRef) return
    const image = imageRef.current
    const parent = parentRef.current
    if(onLoad) {
      onLoad({
        loaded:true,
        info: getImageInfo({parent, image})
      })
    }
    //let timeout
    const resize = () => {
      //clearTimeout(timeout)
      //timeout = setTimeout(() => {
        const info = getImageInfo({parent, image})
        setInfo(info)
      //}, 500)
    }
    if (image) resize()
    window.addEventListener('resize', resize)
    return () => {
      //clearTimeout(timeout)
      window.removeEventListener('resize', resize)
    }
  }, [imageRef, parentRef, onLoad])

  return <LoadableImage
    onLoad={({imageRef}) => setImageRef(imageRef)}
    src={src}
    className={className}
    style={!info ? {...style} : {
      ...style,
      width: info.fill.width,
      height: info.fill.height
    }}
  />
}
export default CenteredThumb
