import React, {useEffect, useRef, useState} from 'react'
import Page from '../../../layout/page/Page'
import Layer from '../../../layout/layer/Layer'
import Single from './Single'
import collection from '../../../config/experience.json'
import navigate from '../../../singletons/navigate'
import Casque from '../../helmet/Casque'
import {getPageMetas} from '../../../commons'
import Highlight from '../../../layout/typo/Highlight'
import scrollScreen from '../../../layout/application/scrollScreen'


const ExperienceItemComponent = ({model, index}) => {

  const {title, summary, thumb, tags, period} = model

  const url = '/experience/' + model.id

  const setLayer = (e) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(url)
  }

  const image_url = '/static_files/' + thumb



  return (
    <a href={url} className="timeline-step animated fadeInUpShort" onClick={setLayer}>
      <div className="timeline-image">
        {!!model.thumb && (
          <img src={image_url} alt={title} className="animated fadeIn" />
          // <LoadableImage src={image_url} alt={title} className="animated fadeIn"/>
          )}
      </div>
      <div className={'timeline-panel animated ' + (index % 2 ? 'fadeInLeft' : 'fadeInRight')}>
        <div className="timeline-heading">
          <h4>{period}</h4>
          <h4 className="subheading">{title}</h4>
        </div>
        <div className="timeline-body">
          <p>{summary}</p>
          <div className="tags">
            {tags.map((item, key) => (
              <img key={'img-' + key} className="img-centered"
                   src={'/static_files/img/logos/base/code-sm-' + item + '.png'}
                   alt=""/>
            ))}
          </div>
        </div>
      </div>
    </a>
  )
}


const ExperiencesListComponent = ({collection, setLayer}) => {
  return (
    <div className="row no-gutters" style={{padding: '50px 0'}}>
      <div className="col-lg-12">

        <div className="timeline">
          {collection.map((model, index) => (
            <ExperienceItemComponent
              setLayer={setLayer}
              key={model.id}
              index={index}
              model={model}
            />
          ))}
        </div>


      </div>
    </div>
  )
}


const ExperiencesList = ({match}) => {

  const [state, setState] = useState({
    layer: null
  })

  const {layer} = state
  const {id} = match.params
  const basePath = '/experience'

  const showItem = useRef((id) => {
    if (id) {
      const model = collection.find(o => o.id === id)
      setLayer(<Single model={model} basePath={basePath} close={closeHandler}/>)
    }
  })


  useEffect(() => {
    scrollScreen(0, 0)
  })

  useEffect(() => {
    showItem.current(id || null)
  }, [id, showItem])

  const setLayer = (content) => setState({
    layer: content
  })

  const closeHandler = () => {
    setLayer(null)
    navigate(basePath)
  }


  return (
    <React.Fragment>

      <Page className="animated fadeIn">
        <React.Fragment>
          <Layer title={id ?  collection.find(o => o.id === id)?.title || undefined : undefined } open={!!layer} close={closeHandler} children={layer}/>
          <Casque
            {...getPageMetas({}).experiences}
          />
          <div className="container">

            <div className="intro-section">
              <h1><Highlight>Where</Highlight> I worked</h1>
              <h2>Here are the companies and the positions I held, <br /> beside my freelance work</h2>
            </div>
          </div>
          <ExperiencesListComponent
            basePath={basePath}
            collection={collection} setLayer={setLayer}/>
        </React.Fragment>
      </Page>
    </React.Fragment>
  )
}

ExperiencesList.propTypes = {}
ExperiencesList.defaultProps = {}

export default ExperiencesList
