import React from "react";
import { Router, Route } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import history from "../singletons/history";
import ApplicationLayout from "../layout/application/ApplicationLayout";
import Home from "../pages/Home";
import ProjectsRouter from "../components/projects/ProjectsRouter";
import ExperiencesRouter from "../components/experiences/ExperiencesRouter";
import LabsRouter from "../components/labs/LabsRouter";
import About from "../pages/About";

const Application = (props) => (
  <ApplicationLayout {...props}>
    <Route exact path="/" component={Home} />
    <Route path="/projects" component={ProjectsRouter} />
    <Route path="/experience" component={ExperiencesRouter} />
    <Route path="/labs" component={LabsRouter} />
    <Route path="/about" component={About} />
  </ApplicationLayout>
);

const router = (
  <ConnectedRouter history={history}>
    <Router history={history}>
      <Route path="/" component={Application} />
    </Router>
  </ConnectedRouter>
);

export default router;
