import React, {useEffect, useRef, useState} from 'react'
import Page from '../../../layout/page/Page'
import Single from './Single'
import useLayer from '../../../layout/layer/useLayer'
import withProjects from '../HOC/withProjects'
import {getPagination} from '../../../utils/pagination'
import withARCLoader from '../../arc/withARCLoader'
import CenteredThumb from './CenteredThumb'
import {Row} from '../../../layout/grid'
import navigate from '../../../singletons/navigate'
import Casque from '../../helmet/Casque'
import {getPageMetas} from '../../../commons'
import {getMetasFromModel} from '../utils'
import Highlight from '../../../layout/typo/Highlight'
import scrollScreen, {getScrollOffset} from '../../../layout/application/scrollScreen'


export const ProjectItemComponent = ({basePath, model}) => {
  const parentRef = useRef()
  //const setLayerHandler = () => setLayer(<Single setLayer={setLayer} model={model}/>)
  const setLayerHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(basePath + '/' + model.id)
  }
  const {title, subtitle} = model
  return (
    <div className="col-sm-6 col-md-4  portfolio-item animated fadeIn" style={{animationDuration: '500ms'}}>
      <a href={`${basePath}/${model.id}`} className="portfolio-link" onClick={setLayerHandler}>
        <div ref={parentRef} className="portfolio-image">
          {!!model.thumb && (
              <div className="fadeInUpShort animated">
                <CenteredThumb parentRef={parentRef} src={model.thumb.replace('http:', 'https:')}/>
              </div>
              )}
        </div>
        <div className="portfolio-hover animated zoomIn">
          <div className="portfolio-hover-content">
            <i className="fa fa-plus fa-3x"/>
          </div>
        </div>
      </a>
      <a href={`${basePath}/${model.id}`} className="portfolio-caption animated fadeInUpShort" onClick={setLayerHandler}>
        {!!title && (<h4 className="ellipsis">{title}</h4>)}
        {!!subtitle && (<p className="ellipsis">{subtitle}</p>)}
      </a>
    </div>
  )
}


const ProjectsList = withProjects(withARCLoader(({collection = [], onCloseLayer, start, id, pageSize, setLayer, setCasque, basePath}) => {

  const showItem = useRef((id) => {
    if (id) {
      const model = collection.find(o => o.id === id)
      const layer = <Single basePath={basePath} model={model} close={onCloseLayer}/>
      setLayer(layer)
      setCasque(model)
    }
  })

  useEffect(() => {
    if (id) {
      showItem.current(id || null)
    } else {
      onCloseLayer()
    }
  }, [id, showItem])



  return (
      <Row className="no-gutters projects">
        {collection.slice(start, start + pageSize).map(model => (
            <ProjectItemComponent basePath={basePath} setLayer={setLayer} key={model.id} model={model}/>
        ))}
      </Row>
  )

  // return (<LazyLoadComponent
  //   listClassName={'row no-gutters projects'}
  //   items={collection.slice(start, start + pageSize).map(model => (
  //     <ProjectItemComponent setLayer={setLayer} key={model.id} model={model}/>
  //   ))}
  //   pageSize={6}
  // />)
  // return (
  //   <React.Fragment>
  //     <div className="row no-gutters projects">
  //
  //       <Waterfall delay={20} key={page}>
  //         {collection.slice(start, start + pageSize).map(model => (
  //           <ProjectItemComponent setLayer={setLayer} key={model.id} model={model}/>
  //         ))}
  //       </Waterfall>
  //
  //
  //     </div>
  //
  //     <Pagination
  //       items={collection}
  //       pageSize={pageSize}
  //       current={page}
  //       basePath={'/projects/page/'}
  //     />
  //   </React.Fragment>
  // )
}))


const ProjectsScreen = ({pageSize, match}) => {

  const basePath = '/projects'
  const [layer, setLayer, offset] = useLayer(null)
  const [casque, setCasque] = useState(null)
  const {id} = match.params

  const setLayerHandler = (v) => {
    setLayer(v, getScrollOffset().top)
  }

  const onCloseHandler = () => {
    setLayer(null)
    setCasque(null)
    navigate(basePath)
  }

  const pagination = getPagination({page: 1, pageSize})

  return (
    <Page
      layerTitle={casque?.title || undefined}
      layer={layer}
      offset={offset}
      onCloseLayer={onCloseHandler}
      className="animated fadeIn portfolio">
      <React.Fragment>
        <Casque
          {...getPageMetas({}).projects}
        />
        {!!casque && (
          <Casque
            {...getMetasFromModel({model: casque, basePath})}
          />
        )}

        {!layer && (
            <div className="container">
              <div className="intro-section">
                <h1><Highlight>Highlights</Highlight> on projects I worked on</h1>
                <h2>Here's a few works i've worked on and shipped</h2>
              </div>

              <ProjectsList
                  id={id}
                  basePath={basePath}
                  setLayer={setLayerHandler}
                  setCasque={setCasque}
                  onCloseLayer={onCloseHandler}
                  start={pagination.start}
                  page={pagination.page}
                  pageSize={pageSize}
              />
            </div>
        )}


      </React.Fragment>

    </Page>

  )
}


ProjectsScreen.propTypes = {}
ProjectsScreen.defaultProps = {
  pageSize: 100
}

export default ProjectsScreen
