const config = {
    name: 'tags',
    //used in the reducers
    uppercaseName: 'TAG',
    // useful to map objects in collection
    modelProps: ['id'],
    // can be an empty list (might be usefull if you need paging...)
    collectionProps: [],
    // path to your rest server
    paths: {
        item: '//api.sunny.fr/api/tag/{id}',
        collection: '//api.sunny.fr/api/tag'
    }
}
export default config
