const config = {
    name: 'labs',
    //used in the reducers
    uppercaseName: 'LAB',
    // useful to map objects in collection
    modelProps: ['id'],
    // can be an empty list (might be usefull if you need paging...)
    collectionProps: [],
    // path to your rest server
    paths: {
        item: 'https://api.sunny.fr/api/lab/{id}',
        collection: 'https://api.sunny.fr/api/lab'
    }
}
export default config
