import React, {useRef, useState} from 'react'
import SlideUp from '../../layout/layer/SlideUp'
import DialogContent from '@material-ui/core/DialogContent'
import Carousel from './Carousel'
import Dialog from '@material-ui/core/Dialog'

const CarouselDialog = ({open, toggle}) => {

  const ref = useRef()
  return (
    <Dialog
      transitionDuration={500}
      TransitionComponent={SlideUp}
      //fullScreen={true}
      //fullWidth={true}
      open={open} onClose={toggle}>
      <div ref={ref}>
        <Carousel dialogContentRef={ref} />
      </div>
    </Dialog>
  )
}


export default CarouselDialog
