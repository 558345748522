import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {initGA} from './components/analytics/ga'
//import * as serviceWorker from './_serviceWorker'


/** GLOBALS **/
initGA()
ReactDOM.render(<App />, document.getElementById('root'));

// if (module.hot) {
//     module.hot.accept('./App', () => {
//         ReactDOM.render(<App />, document.getElementById('root'))
//     })
// }

serviceWorkerRegistration.register();
reportWebVitals();

//serviceWorker.register()

