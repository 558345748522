import React from 'react'
import LogoSunny from '../../assets/images/logo-sunny.fr.png'
import navigate from '../../singletons/navigate'
import HeaderMenu from '../../pages/HeaderMenu'
import './header.scss'
import Button from '@material-ui/core/Button'
import useToggle from '../../hooks/useTogglable'


export const HeaderComponent = ({shrink}) => {
  const [visible, toggle] = useToggle(false)

  const handleNavigate = (e, to) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(to)
  }

  const classNames = ['navbar', 'navbar-dark', 'navbar-expand-md', 'fixed-top']


  if (!shrink) classNames.push('navbar-shrink')

  return (
    <header className={shrink ? 'transparent' : ''}>

      <nav className={classNames.join(' ')}>
        <div className="container">
          <Button href="/" className="navbar-brand page-scroll" onClick={(e) => handleNavigate(e, '/')}>
            <img src={LogoSunny} alt=""/>
          </Button>

          <button className="navbar-toggler" onClick={toggle}>
            <span className="navbar-toggler-icon"/>
          </button>

          {/*<div className="collapse navbar-collapse justify-content-md-end">*/}
          <div className={'navbar-collapse justify-content-md-end' + (visible ? '' : ' collapse')}>
            <div className="header-mobile-menu">

              <HeaderMenu close={() => toggle(false)}/>

            </div>
            {/*<form className="form-inline my-2 my-md-0">*/}
            {/*    <input className="form-control" type="text" placeholder="Search">*/}
            {/*</form>*/}
          </div>
        </div>
      </nav>

    </header>
  )

}

HeaderComponent.propTypes = {
  //classes: PropTypes.object.isRequired,
}


export default HeaderComponent
