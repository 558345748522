import React, {useEffect} from 'react'
import {Provider} from 'react-redux'
import store from './config/store'
import router from "./config/router";
import {ARCProvider} from 'react-arc'
import withRoot from './withRoot'


const App = () => {
    // useEffect(() => {
    //
    //
    //
    //   // {
    //   //   // gaOptions: {
    //   //   //   appVersion: versionInfo.version,
    //   //   // },
    //   // }
    //
    //   // emitGAEvent({
    //   //   category: 'Versions',
    //   //   action: versionInfo.name ? versionInfo.version + ' ' + versionInfo.emoji : 'Development 🚧',
    //   // })
    // })
    return (
        <Provider store={store}>
            <ARCProvider store={store}>
                {router}
            </ARCProvider>
        </Provider>
    );
}

export default withRoot(App);
