import {push} from 'react-router-redux'
import store from "../config/store";


export default function (path) {
    return store.dispatch(push(path))
    // return setTimeout(() => {
    //     store.dispatch(push(path))
    // }, 600)
}
