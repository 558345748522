import React, {useEffect, useRef, useState} from 'react'
import Page from '../../../layout/page/Page'
//import Pagination from '../../../layout/pagination/Pagination'
import projectsConfig from '../labsConfig'
import LabsContainer from '../containers/LabsContainer'
import {ProjectItemComponent} from '../../projects/components/ProjectsScreen'

import useLayer from '../../../layout/layer/useLayer'
import {getPagination} from '../../../utils/pagination'
import navigate from '../../../singletons/navigate'
import Single from '../../projects/components/Single'
import withARCLoader from '../../arc/withARCLoader'
import Casque from '../../helmet/Casque'
import {getPageMetas} from '../../../commons'
import {getMetasFromModel} from '../../projects/utils'
import Highlight from '../../../layout/typo/Highlight'
import scrollScreen, {getScrollOffset} from '../../../layout/application/scrollScreen'


const LabsListComponent = withARCLoader(({collection, loaded, id, start, page, pageSize, setLayer, setCasque, basePath, onCloseLayer}) => {


  const showItem = useRef((id) => {
    if (id) {
      const model = collection.find(o => o.id === id)
      setCasque(model)
      setLayer(<Single model={model} basePath={basePath} close={onCloseLayer}/>)
    }
  })

  useEffect(() => {
    if (id) {
      showItem.current(id || null)
    } else {
      onCloseLayer()
    }
  }, [id, showItem])


  return (
    <div>
      {/*<div className="row">*/}
      {/*  <div className="col-lg-12 text-center">*/}
      {/*    <h2 className="section-heading">Labs</h2>*/}
      {/*    <h3 className="section-subheading text-muted">My experiments and unreleased projects</h3>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="no-gutters row projects">
        {collection.slice(start, start + pageSize).map(model => (
          <ProjectItemComponent basePath={basePath} setLayer={setLayer} key={model.id} model={model}/>
        ))}
      </div>

      {/*{!!loaded && (*/}
      {/*  <Pagination items={collection} pageSize={pageSize} current={page}*/}
      {/*              basePath={'/labs/page/'}/>*/}
      {/*)}*/}
    </div>
  )
})


const LabsList = ({pageSize, match}) => {
  // const [layer, setLayer] = useLayer(null)
  //   //const [visible, toggle] = useToggle(false)
  //   const {page} = match.params
  //   const previousPage = usePrevious(page)
  //
  //   useEffect(() => {
  //       if (page !== previousPage) {
  //           scrollScreen(0)
  //       }
  //   })

  const basePath = '/labs'
  const [layer, setLayer, offset] = useLayer(null)
  const [casque, setCasque] = useState(null)
  const {id} = match.params


  const setLayerHandler = (v) => {
    setLayer(v, getScrollOffset().top)
  }

  const onCloseHandler = () => {
    setLayer(null)
    setCasque(null)
    navigate(basePath)
  }




  const pagination = getPagination({page: 1, pageSize})
  return (
    <Page
      layerTitle={casque?.title || undefined}
      layer={layer}
      offset={offset}
      onCloseLayer={onCloseHandler}
      className="animated fadeIn portfolio">

      <React.Fragment>
        <Casque
          {...getPageMetas({}).labs}
        />
        {!!casque && (
          <Casque
            {...getMetasFromModel({model: casque, basePath})}
          />
        )}
        {!layer && (
            <div className="container">
              <div className="intro-section">
                <h1>Labs, Demos, Experiences</h1>
                <h2>I really enjoy <Highlight>creating</Highlight> weird and not very meaningful projects...</h2>
                <h3>...for fun or learning</h3>
              </div>
              <LabsContainer
                  ARCConfig={projectsConfig}
                  setLayer={setLayerHandler}
                  setCasque={setCasque}
                  start={pagination.start}
                  page={pagination.page}
                  pageSize={pageSize}
                  id={id}
                  onCloseLayer={onCloseHandler}
                  basePath={basePath}
                  component={LabsListComponent}/>
            </div>
        )}

      </React.Fragment>


    </Page>
  )
}

LabsList.propTypes = {}
LabsList.defaultProps = {
  pageSize: 100
}

export default LabsList
