import {/*green900, green700, purple400, green700, blueGrey400,blueGrey900*/} from '@material-ui/core';
import {createMuiTheme} from '@material-ui/core';
//
// const tableRowHeight = 36
// const globalColor = blueGrey900
// const lightColor = '#FFF'

//{
    // palette: {
    //     primary1Color: globalColor,
    // },
    // appBar: {
    //     color: lightColor,
    //     textColor: blueGrey900,
    //     height: 90
    // },
    // drawer: {
    //     color: globalColor
    // },
    // tableHeader: {
    //     backgroundColor: '#FAFAFA',
    // },
    // tableRow: {
    //     height: tableRowHeight,
    // },
    // tableRowColumn: {
    //     height: tableRowHeight,
    // },
    // tableHeaderColumn: {
    //     height: tableRowHeight
    // }
//}

const theme = createMuiTheme({
  palette:{
    // background:{
    //   default: '#232323'
    // }
    type: 'dark'
  }
});


export default theme
