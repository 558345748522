import React from 'react';
import {AbstractCollectionComponent} from 'react-arc'

const MissingComponent = () => (<div><h1>Missing</h1></div>)


export const filterARCCONFIG = (props) => Object.keys(props).reduce((state, current) => current === 'ARCConfig' ? state : {
    ...state,
    [current]: props[current]
}, {})

export class Container extends AbstractCollectionComponent {
    constructor(props) {
        super(props)
        if (window.fetch) this.actions.standAloneFetchAll = this.agent
    }

    agent = (params) => {
        const url = this.actions.decorate(this.actions.config.paths.collection, params)
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: this.actions.methods.read,
                headers: this.actions.methods.headers
            }).then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error(response)
            }).then(data => {
                resolve({data})
            }).catch(e => {
                reject(e)
            })
        })
    }

    render() {
        const Component = this.props.component || MissingComponent
        return <Component {...filterARCCONFIG(this.props)}
                          error={this.getError()}
                          loaded={this.isLoaded()}
                          collection={this.isLoaded() ? this.getCollection() : []}/>
    }
}

export default Container
