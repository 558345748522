import config from '../../config/config'

const projectConfig = {
    name: 'projects',
    //used in the reducers
    uppercaseName: 'PROJECT',
    // useful to map objects in collection
    modelProps: ['id'],
    // can be an empty list (might be usefull if you need paging...)
    collectionProps: [],
    // path to your rest server
    paths: {
        item: config.api + 'api/project/{id}',
        collection: config.api + 'api/project'
    }
}
export default projectConfig
