import React from 'react'
import PropTypes from 'prop-types'
import Casque from '../../helmet/Casque'
import {getMetasFromModel} from '../../projects/utils'
import {getPageMetas} from '../../../commons'
import LoadableImage from '../../../layout/image/LoadableImage'

const Single = ({model, basePath}) => {
  const genHTML = (content) => ({__html: content})
  const {title, subtitle, pano, tags = [], description} = model
  return (
    <React.Fragment>

      <Casque
        {...getMetasFromModel({
          model: {
            ...model,
            images: pano ? [{path: getPageMetas({}).url + '/static_files/' + pano}] : []
          }, basePath
        })}
      />
      <div className="container">
      <div className="fadeInUpShort animated" style={{animationDelay: '500ms'}}>
        {/*<h1>{title}</h1>*/}
        {/*{!!subtitle && <p>{subtitle}</p>}*/}

        <div className="intro-section">
          <h1>{title}</h1>
          <p className="item-intro">{subtitle}</p>
        </div>

        <div className="layer-gallery">
        <img
          style={{maxWidth: '100%'}}
          className="main-image" title={title} alt={title}
          src={'/static_files/' + pano}
        />
        </div>

        <div className="layer-content">
        <div dangerouslySetInnerHTML={genHTML(description)}/>

        </div>

        {tags.filter(p => !!p.path).length > 0 && (
          <div className="tags">
            <h5>Technologies</h5>
            <ul>
              {tags.filter(p => !!p.path).map(item => (
                <li key={item.id}>
                  <LoadableImage
                    style={{height: 25, width: 25, marginRight: 20}}
                    className="animated fadeInUpShort img-centered"
                    title={item.title}
                    alt={item.title}
                    src={item.path.replace('http:', 'https:')}/>
                  <span>{item.title}</span>
                </li>

              ))}
            </ul>
          </div>
        )}


        {/*{tags.length > 0 && (*/}
        {/*  <div className="tags">*/}
        {/*    {tags.map(item => (*/}
        {/*      <img className="tech-skill" key={item}*/}
        {/*           src={'/static_files/img/logos/base/code-sm-' + item + '.png'} alt={item}/>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*)}*/}

      </div>

      </div>

    </React.Fragment>

  )
}

Single.propTypes = {
  model: PropTypes.object
}
Single.defaultProps = {}

export default Single
