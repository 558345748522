const commons = {
  title: 'Sunny - Alaa El Helw',
  description: 'Architect, Developer, Web enthusiast in France',
  image: 'https://sunny.fr/static_files/img/alaa-el-helw/profile.jpg',
  url: 'https://sunny.fr',
}


export const getPageMetas = ({title, description, image, url}) => {
  return {
    title: title || commons.title,
    description: description || commons.description,
    image: image || commons.image,
    url: url || commons.url,
    projects: {
      title: 'Projects',
      description: "Projects i've been working on",
      url:'https://sunny.fr/projects'
    },
    experiences: {
      title: 'Experience',
      description: "My professional timeline",
      url:'https://sunny.fr/projects'
    },
    labs: {
      title: 'Labs',
      description: "Small or Unreleased projects and demo",
      url:'https://sunny.fr/labs'
    },
    about: {
      title: 'About',
      description: "Get in touch with me",
      url:'https://sunny.fr/about'
    }
  }
}

export default commons