import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles, Drawer, List} from '@material-ui/core';
import SidebarMenu from "../../pages/SidebarMenu";
import './sidebar.css'

const drawerWidth = 100;

const styles = theme => ({

    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        //position: 'relative',
        top: 64,
        height: 'calc(100% - 64px)',
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    }
});

// import './sidebar.css'
// import SidebarMenu from '../../pages/SidebarMenu'
//
// const sideBarStyle = {height:'calc( 100% - 90px)', top: '90px', color:'#FFF'}
//
// export class SideBarComponent extends Component {
//     render() {
//         return (<Drawer containerStyle={sideBarStyle} width={100} open={this.props.open}
//                         onRequestClose={() => this.props.toggler(true)}>
//             <SidebarMenu />
//         </Drawer>)
//     }
// }
//
// export default SideBarComponent


class SideBarComponent extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Drawer
                type="persistent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                open={this.props.open}
                onRequestClose={() => this.props.toggler(false)}
            >
                <div className={classes.drawerInner}>
                    <List className={classes.list}>
                        <SidebarMenu/>
                    </List>
                </div>
            </Drawer>
        );
    }
}


SideBarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideBarComponent);
