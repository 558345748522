const defaultState = {
    width: 1200,
    mode: 'desktop',
    header: true,
    animations: true
}

export default function reducer(state = defaultState, action) {

    switch (action.type) {
        case "DEVICE_CHANGE_DIMENSION": {
            return {
                ...state,
                width: action.payload.width,
                mode: action.payload.mode
            }
        }
        case "DEVICE_TOGGLE_HEADER": {
            return {
                ...state,
                header: action.payload.status,
            }
        }
        case "DEVICE_TOGGLE_ANIMATIONS": {
            return {
                ...state,
                animations: action.payload.status,
            }
        }

        default:
            return state

    }
}
