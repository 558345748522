import React, { Component } from "react";
import Page from "../layout/page/Page";
import AboutComponent from "../components/about/AboutComponent";
import Casque from '../components/helmet/Casque'
import {getPageMetas} from '../commons'

class About extends Component {
  render() {
    return (
      <Page className="animated fadeIn about">
        <Casque
          {...getPageMetas({}).about}
        />
        <AboutComponent />
      </Page>
    );
  }
}

About.propTypes = {};
About.defaultProps = {};

export default About;
