import React, { useEffect, useRef, useState } from "react";
import "./home.scss";
//import CenteredThumb from "../projects/components/CenteredThumb";
import Page from '../../layout/page/Page'
import scrollScreen from '../../layout/application/scrollScreen'



//
// const Cruz = ({sectionRef, introTextRef, color}) => {
//   const [state, setState] = useState({
//     marginTop: 60,
//   });
//
//   const cruzRef = useRef();
//   const { marginTop } = state;
//
//
//   // const rotate = (angleX = 0, angleY = 0, angleZ) => {
//   //     //console.log(angleX, angleY, angleZ)
//   //     //const angleMax = 30;
//   //
//   //     const max = (v, angleMax) => v / 5 //Math.abs(v)  > angleMax ? angleMax : v
//   //
//   //     const cruz = cruzRef.current
//   //
//   //     const transforms = 'translate3d(' + max(angleY, 30) + 'px,' + max(angleX, 30) + 'px, 0) translateZ(0) '
//   //         + 'rotateX(' + max(0, 30) + 'deg) rotateY(' + max(angleY, 30) + 'deg)' + (angleZ ? ' rotateZ(' + max(angleZ, 30) + 'deg)' : '')
//   //
//   //     //console.log(transforms)
//   //
//   //     cruz.style.transform = transforms
//   //
//   //     //document.querySelector('section.home').style.backgroundPosition = (50 + max(angleX, 5)) + '% ' + (50 + max(angleY, 5)) + '%'
//   //
//   //     //document.querySelector('.intro-lead-in').innerHTML= 'rotateX(' + max(angleX) + 'deg) rotateY(' + max(angleY) + 'deg)' + ( angleZ ? ' rotateZ(' + max(angleZ) + 'deg)' : '')
//   //     // const cruz = this.$el.find('.cruz');
//   //     // cruz.css({
//   //     //     'transform': 'rotateX(' + angleX + 'deg) rotateY(' + angleY + 'deg)' + ( angleZ ? ' rotateZ(' + angleZ + 'deg)' : '')
//   //     // });
//   // }
//
//   useEffect(() => {
//     const setOffset = () => {
//       const height = sectionRef.current.offsetHeight;
//       const canvasHeight = introTextRef.current.offsetHeight;
//       const marginTop = Math.floor((height - canvasHeight) / 2);
//       setState({ marginTop });
//     };
//     setOffset();
//     //const deviceOrientationListener = (e) => rotate(e.beta, e.gamma)
//     window.addEventListener("resize", setOffset);
//     // if (window.DeviceOrientationEvent) {
//     //     window.addEventListener('deviceorientation', deviceOrientationListener)
//     // }
//
//     return () => {
//       window.removeEventListener("resize", setOffset);
//       // if (window.DeviceOrientationEvent) {
//       //     window.removeEventListener('deviceorientation', deviceOrientationListener)
//       // }
//     };
//   }, [sectionRef, introTextRef]);
//   const textColorStyle = {color}
//   return (
//       <div className="cruz-canvas" style={{ marginTop }}>
//         <div className="cruz" ref={cruzRef}>
//           <div className="line" style={{backgroundColor:color}} />
//           <div className="line" style={{backgroundColor:color}} />
//           <div className="symbol">
//             <div className="front">
//               <i className="icon-flash" style={textColorStyle} />
//             </div>
//             <div className="back">
//                 <span style={textColorStyle}>
//                   Challenge
//                   <br /> myself
//                 </span>
//             </div>
//           </div>
//           <div className="symbol">
//             <div className="front">
//               <i className="icon-code" style={textColorStyle} />
//             </div>
//             <div className="back">
//                 <span style={textColorStyle}>
//                   Create,
//                   <br />
//                   Develop
//                 </span>
//             </div>
//           </div>
//           <div className="symbol">
//             <div className="front">
//               <i className="icon-beaker-1" style={textColorStyle} />
//             </div>
//             <div className="back">
//                 <span style={textColorStyle}>
//                   Discover,
//                   <br />
//                   Try New Things
//                 </span>
//             </div>
//           </div>
//           <div className="symbol">
//             <div className="front">
//               <i className="icon-exchange" style={textColorStyle} />
//             </div>
//             <div className="back">
//               <span style={textColorStyle}>Share</span>
//             </div>
//           </div>
//           {/*<i className="icon-rocket" />*/}
//           {/*<i className="icon-note-beamed" />*/}
//           {/*<i className="icon-lamp" />*/}
//           {/*<i className="icon-emo-beer" />*/}
//           {/*<i className="icon-emo-coffee" />*/}
//           {/*<i className="icon-fast-food" />*/}
//         </div>
//
//         {/*<div className="globe"/>*/}
//         {/*<div className="globe vert"/>*/}
//       </div>
//   )
// }

const FlatCruz = ({ color, className,style}) => {
  const textColorStyle = {color}
  return(
    <div className={`cruz-canvas ${className}`} style={style} >
      <div className="cruz">
        <div className="line" style={{backgroundColor:color}} />
        <div className="line" style={{backgroundColor:color}} />
        <div className="symbol">
          <div className="front">
            <i className="icon-flash" style={textColorStyle} />
          </div>
          <div className="back">
                <span style={textColorStyle}>
                  Challenge
                  <br /> myself
                </span>
          </div>
        </div>
        <div className="symbol">
          <div className="front">
            <i className="icon-code" style={textColorStyle} />
          </div>
          <div className="back">
                <span style={textColorStyle}>
                  Create,
                  <br />
                  Develop
                </span>
          </div>
        </div>
        <div className="symbol">
          <div className="front">
            <i className="icon-beaker-1" style={textColorStyle} />
          </div>
          <div className="back">
                <span style={textColorStyle}>
                  Discover,
                  <br />
                  Try New Things
                </span>
          </div>
        </div>
        <div className="symbol">
          <div className="front">
            <i className="icon-exchange" style={textColorStyle} />
          </div>
          <div className="back">
            <span style={textColorStyle}>Share</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const source = [
  {
    "author": "stephane.rigobert",
    "title": "Biscarosse - Lac Nord",
    "location": "Biscarrosse, Les Landes",
    "site": "https://www.flickr.com/photos/stephanerigobert/37158011722/",
    "image_url": "/static_files/img/home/bisca-lac.jpg",
    "textColor": "#FFF",
    textShadow: "0 1px 2px rgba(0,0,0,.8)"
  },
]


const Content = ({image}) => {
  return <div className="intro-text animated fadeIn">
    <FlatCruz color={image.textColor} className="animated fadeIn" style={{animationDuration: '1000ms', animationDelay: '300ms'}} />
    <h1 className="intro-lead-in animated fadeInUpShort" style={{color: image.textColor, textShadow: image.textShadow, animationDelay: '500ms'}}>Alaa El Helw</h1>
    <p className="intro-small animated fadeInUpShort" style={{color: image.textColor, textShadow: image.textShadow, animationDelay: '800ms'}}>Architect, Developer, Web enthusiast from France</p>
  </div>
}


const HomeComponent = ({ sectionRef }) => {

  useEffect(() => {
    scrollScreen(0, 0)
  })

  const image = source[0]


  return (
    <Page style={{height: '100vh', backgroundImage: `url(${image.image_url})`}} className="animated fadeIn home">
      <Content image={image} />
    </Page>

  )

  // return (
  //   <div>
  //     <div key={image.image_url} style={{animationDuration: DURATION + 'ms', backgroundColor: image.textColor}} className="time-elapsed"/>
  //
  //     <div ref={introTextRef} className="intro-text">
  //       <Cruz sectionRef={sectionRef} color={image.textColor} introTextRef={introTextRef} />
  //       <h1 className="intro-lead-in" style={{color: image.textColor, textShadow: image.textShadow}}>Alaa El Helw</h1>
  //       <p className="intro-small" style={{color: image.textColor, textShadow: image.textShadow}}>Architect, Developer, Web enthusiast from France</p>
  //     </div>
  //   </div>
  // );
};

export default HomeComponent;
