import React from 'react'
import PropTypes from 'prop-types'

export function Row({ className, onClick, style, children }) {
  const addClassName = ['row'].concat(className ? className.split(' ') : []).join(' ')
  return (<div className={addClassName} onClick={onClick} style={style}>
    {children}
  </div>)
}

Row.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string
};

Row.defaultProps = {
    className: '',
    style: {}
};

export default Row;