import React from 'react';
import {Switch, Route} from 'react-router'
import ExperiencesList from "./components/ExperiencesList";
import './experience.scss'
function ExperiencesRouter() {
    return (
        <Switch>
            <Route name="experience" path="/experience/:id?" component={ExperiencesList}/>
        </Switch>
    );

}


export default ExperiencesRouter;
