import {getPageMetas} from '../../commons'
import React from 'react'

const getImage = (images) => {
  if(images.length < 1) return undefined
  const path = images[0].path
  if(!path) return undefined
  return path.replace('http:', 'https:')
}

export const getMetasFromModel = ({model, basePath}) => {
  const {title, subtitle,  images, /*summary, tags, description*/} = model
  return {
    ...getPageMetas({}).projects,
    title,
    description: subtitle,
    image: getImage(images),
    url: getPageMetas({}).url + basePath + '/' + model.id
  }
}