import React, {useEffect, useState} from 'react'
import LoadableImage from '../../layout/image/LoadableImage'
import DialogContent from '@material-ui/core/DialogContent'
import CenteredThumb from '../projects/components/CenteredThumb'


const source = [
  {
    "author": "stephane.rigobert",
    "title": "Biscarosse - Lac Nord",
    "location": "Biscarrosse, Les Landes",
    "site": "https://www.flickr.com/photos/stephanerigobert/37158011722/",
    "image_url": "/static_files/img/home/bisca-lac.jpg",
    "textColor": "#FFF",
    textShadow: "0 1px 2px rgba(0,0,0,.8)"
  },
  {
    "author": "hassan Bensliman",
    "title": "Biscarrosse lake",
    "location": "Biscarrosse, Les Landes",
    "site": "https://www.flickr.com/photos/78950271@N03/49078096992/",
    "image_url": "/static_files/img/home/bisca-lac-2.jpg",
    "textColor": "#FFF",
    textShadow: "0 1px 2px rgba(0,0,0,.8)"
  },
  {
    author: 'MARCB Roazhon',
    title: 'Maisons Jumelles',
    location: 'Plage centrale Biscarrosse, Les Landes',
    site: 'https://www.flickr.com/photos/152468737@N03',
    image_url: '/static_files/img/home/maisons-jumelles.jpg',
    textColor: '#FFF',
    textShadow: "0 1px 2px rgba(0,0,0,.8)"
  },
  {
    author: 'Cathy Baillet',
    title: 'Cache-cache',
    location: ' Plage centrale Biscarrosse, Les Landes',
    site: 'https://www.cathybaillet.com/fr/accueil.html',
    image_url: '/static_files/img/home/bisca-flikr.jpg',
    textColor: '#343434',
    textShadow: "0 1px 2px rgba(255,255,255,.8)"
  },
]


const trimUrl = (str) => {
  return str.replace('https://', '')
    .replace('http://', '')
    .replace('www.', '')
}

const Thumb = ({parentRef, image, toggle}) => {
  const {author, title, location, site, image_url }= image
  return <React.Fragment>
    <CenteredThumb

      key={image_url + '-image'}
      onClick={toggle}
      parentRef={parentRef}
      className="image-cover animated fadeIn"
      src={image_url}
      style={{animationDuration: '1s', maxWidth: '100%'}}
    />
    <div key={image_url + '-caption'} className="image-copyright animated fadeInUp" onClick={toggle}>
      <div className="author-title"><strong>{title}</strong></div>
      <div className="author-subtitle">{location}</div>
      <div> {author}</div>
      <div className="author-subtitle"><a target={'_blank'} rel="noopener noreferrer" href={site}>{trimUrl(site)}</a></div>
    </div>
  </React.Fragment>
}


function shuffle(array) {
  return array.slice().sort(() => Math.random() - 0.5);
}

const DURATION = 5000

const Carousel = ({dialogContentRef}) => {

  const [index, setIndex] = useState(0)
  const images = shuffle(source)
  useEffect(() => {
    let timeout
    const next = () => setIndex((index) => {
      timeout = setTimeout(next, DURATION)
      if(index < images.length -1) {
        return index + 1
      }
      return 0
    })
    timeout = setTimeout(next, DURATION)
    return () => {
      clearTimeout(timeout)
    }
  })

  const image = source[index]

  return (
    <div className="biscarousel">
      <div key={image.image_url} style={{animationDuration: DURATION + 'ms', backgroundColor: image.textColor}} className="time-elapsed"/>
      <Thumb parentRef={dialogContentRef} image={image} />
    </div>
  )
}


export default Carousel
