import { useState } from 'react'

function useToggle(defaultState = false) {
  const [state, setState] = useState({ visible: defaultState })
  const toggleHandler = (visible) =>
    setState({
      visible: typeof visible === 'boolean' ? visible : !state.visible,
    })
  return [state.visible, toggleHandler]
}

export default useToggle
