import React, {useEffect, useRef, useState} from 'react'
import Loader from '../loader/Loader'


const styles = {
    loading: {
      opacity: 0.001
    },
    loader: {
        // position: 'absolute',
        // top: 50,
        // left: 50
    }
}

const LoadableImage = ({src, alt, style, className, onClick, onLoad}) => {
    const [loaded, setLoaded] = useState(false)
    const isMounted = useRef(true)
    const imageRef = useRef()


    const onLoadRef = useRef(() => {
        if (!isMounted.current) return
        setLoaded(true)
        if (onLoad) onLoad({imageRef, loaded: true})
    })
    const onErrorRef = useRef(() => {
        if (!isMounted.current) return
        if (onLoad) {
            onLoad({imageRef, loaded: false, error})
        }
    })
    return <React.Fragment>
        {loaded ? null : (<Loader loaderStyle={{color: '#7b8f29'}}/>)}
        <img ref={imageRef} className={loaded ? className : ''} src={src} alt={alt} style={loaded ? style : styles.loading}
             onClick={onClick}
             onLoad={onLoadRef.current}
             onError={onErrorRef.current}
        />
    </React.Fragment>
}

export default LoadableImage
