import React, {useState} from 'react'
import PropTypes from 'prop-types'
import './layer.scss'
//import SlideUp from './SlideUp'
import Dialog from '@material-ui/core/Dialog'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import {Close} from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import LogoSunny from '../../assets/images/logo-sunny.fr.png'
import DialogContent from '@material-ui/core/DialogContent'
//import FadeIn from './FadeIn'
import SlideUp from './SlideUp'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'rgba(40,40,40, .8)'
    //backgroundColor: '#bdd64d'
  },
  title: {
    color: '#323232',
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))


const Layer = withStyles((theme) => ({
  root: {
    //backgroundColor: '#232323',
    padding:0
  },
  paper: {
    backgroundColor: '#303030',
  },
}))(({children, open, close, classes, title}) => {
  const appBarClasses = useStyles()
  const [_title, setTitle] = useState(title)
  return (<Dialog
    container={() => document.getElementById('root')}
    transitionDuration={500}
    TransitionComponent={SlideUp}
    open={open}
    fullScreen={true}
    fullWidth={true}
    onClose={close}
    classes={classes}
    BackdropProps={{
      style: {
        //backgroundColor: '#303030'
        //backdropFilter: ' blur(5px)',
        //backgroundColor: 'rgba(40, 40, 40, .8)'
      }
    }}
    onEnter={() => setTitle(title)}
  >

    <AppBar
      elevation={0}
      className={appBarClasses.appBar}>
      <Toolbar>
        <Typography variant="h6" className={appBarClasses.title}>
          {/*{_title || (<img style={{width: 107, transform: 'translateY(5px)'}} src={LogoSunny} alt=""/>)}*/}
          <img style={{width: 107, transform: 'translateY(5px)'}} src={LogoSunny} alt=""/>
        </Typography>
        <IconButton edge="end" color="inherit" onClick={close} aria-label="close">
          <Close/>
        </IconButton>
      </Toolbar>
    </AppBar>

    <DialogContent>
      <div className="layer">
        <div className="content">
          {children}
        </div>
      </div>
    </DialogContent>
  </Dialog>)
})


Layer.propTypes = {
  children: PropTypes.any,
  close: PropTypes.func
}
Layer.defaultProps = {}

export default Layer
