import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import LoadableImage from '../../../layout/image/LoadableImage'
import {Row} from '../../../layout/grid'
import Column from '../../../layout/grid/Column'
import ReactPlayer from 'react-player'
// import Slider from 'react-slick'
import CenteredThumb from './CenteredThumb'
//
// const settings = {
//   slider: {
//     // dots: true,
//     // infinite: false,
//     // speed: 500,
//     // slidesToShow: 1,
//     // slidesToScroll: 1,
//     className: 'center',
//     centerMode: true,
//     infinite: true,
//     centerPadding: '200px',
//     slidesToShow: 1,
//     adaptiveHeight: true,
//     speed: 500
//   },
// }
//
const Thumb = ({item}) => {
  const parentRef = useRef()
  return (<div
    ref={parentRef}
    className="layer-thumb"
    style={{
      height: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    {/*<LoadableImage*/}
    {/*  alt={item.title}*/}
    {/*  className="animated fadeInUpShort"*/}
    {/*  src={item.path.replace('http:', 'https:')}/>*/}

    <CenteredThumb
      parentRef={parentRef}
      alt={item.title}
      className="animated fadeInUpShort"
      //style={{maxHeight: 300}}
      src={item.path.replace('http:', 'https:')}/>
  </div>)
}


const getContent = (content) => {
  const found = []
  const div = document.createElement('div')
  div.innerHTML = content
  const iframes = div.querySelectorAll('iframe')
  iframes.forEach(iframe => {
    if (iframe.src.includes('youtube') || iframe.src.includes('youtu.be')) {
      iframe.parentElement.removeChild(iframe)
      found.push(iframe.src)
    }
  })

  return {
    content: div.innerHTML,
    found
  }
}

const Single = ({model, basePath}) => {
  const {title, subtitle, summary, tags, images, description} = model

  const descriptionMetas = getContent(description)
  const summaryMetas = getContent(summary)

  const videos = descriptionMetas.found.concat(summaryMetas.found)

  const genHTML = (content) => ({__html: content})
  const cleanedDescription = descriptionMetas.content
  const cleanedSummary = summaryMetas.content
  return (
    <React.Fragment>

      <div className="fadeInUpShort animated" style={{animationDelay: '500ms'}}>


        <div className="container">
          <div className="intro-section">
            <h1>{title}</h1>
            <p className="item-intro">{subtitle}</p>
          </div>

          <div className="layer-gallery">
            <Row className="">

              {videos.map(url => url && (
                <Column forceClassName="col-md-4 col-sm-12" key={url}>
                  <div className="layer-thumb"
                       style={{
                         height: '200px',
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                       }}>
                    <ReactPlayer height={'100%'} width={'100%'} light controls={true} url={url}/>
                  </div>


                </Column>
              ))}
              {images.map(item => item.path && (
                <Column forceClassName="col-md-4 col-sm-12" key={item.id}>
                  <Thumb
                    item={item}
                  />
                </Column>
              ))}
            </Row>
          </div>
          <div className="layer-content">
            <div className="description" dangerouslySetInnerHTML={genHTML(cleanedSummary)}/>
            <div className="description" dangerouslySetInnerHTML={genHTML(cleanedDescription)}/>
          </div>


          <div className="tags">
            <h5>Technologies</h5>
            <ul>
              {tags.map(item => item.path && (
                <li key={item.id}>
                  <LoadableImage
                    style={{height: 25, width: 25, marginRight: 20}}
                    className="animated fadeInUpShort img-centered"
                    title={item.title}
                    alt={item.title}
                    src={item.path.replace('http:', 'https:')}/>
                  <span>{item.title}</span>
                </li>

              ))}
            </ul>
          </div>

        </div>

      </div>

    </React.Fragment>
  )
}

Single.propTypes = {
  model: PropTypes.object
}
Single.defaultProps = {}

export default Single
