import React from 'react';
import PropTypes from 'prop-types';
import navigate from "../singletons/navigate";
import Button from '@material-ui/core/Button'


function Link(props) {
    const {to, title, close} = props
    const handleNavigate = (e, to) => {
        e.preventDefault()
        e.stopPropagation()
        close();
        navigate(to)
    }
    return (<li><Button href={to} onClick={(e)=>handleNavigate(e,to)}>{title}</Button></li>)
}

Link.propTypes = {};
Link.defaultProps = {
    to: PropTypes.string,
    title: PropTypes.string,
};

function HeaderMenu({close}) {
    return (
        <ul className="nav navbar-nav navbar-right">
            <Link close={close} to={'/projects'} title={'Projects'}/>
            <Link close={close} to={'/experience'} title={'Experience'}/>
            {/*<Link close={close} to={'/labs'} title={<span><i className="icon-beaker-1"/> Labs</span>}/>*/}
            <Link close={close} to={'/labs'} title={'Labs'}/>
            <Link close={close} to={'/about'} title={'About'}/>
        </ul>
    )
}


export default HeaderMenu;
