import React from 'react'
import Header from '../header/Header'
import SideBarComponent from '../sidebar/SideBarComponent'
import './ale.scss'
import useToggle from '../../hooks/useTogglable'
import Casque from '../../components/helmet/Casque'




export const ApplicationLayout = ({location, children}) => {
    const [open, toggle] = useToggle(false)
    const isHome = location.pathname === '/'

    return (<div className="App">

        <Casque />

        <div className="App-shell">
            <div id="header">
                <Header shrink={isHome} toggler={toggle} />
            </div>
            {children}
            <SideBarComponent open={open} toggler={toggle} />
        </div>


    </div>)
}
export default ApplicationLayout