import React, {useEffect} from 'react'
import tagsConfig from './tagsConfig'
import './about.scss'
import withTags from './HOC/withTags'
// import Row from '../../layout/grid/Row'
// import Column from '../../layout/grid/Column'
// import Waterfall from '../../layout/animations/Waterfall'
// import Delay from '../../layout/delay/Delay'
// import CenteredThumb from '../projects/components/CenteredThumb'
import Highlight from '../../layout/typo/Highlight'
import CssWaterfall from '../../layout/animations/CssWaterfall'
import FlipLink from '../../layout/link/FlipLink'
import useToggle from '../../hooks/useTogglable'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import LoadableImage from '../../layout/image/LoadableImage'
import Carousel from './Carousel'
import SlideUp from '../../layout/layer/SlideUp'
import CarouselDialog from './CarouselDialog'
import scrollScreen from '../../layout/application/scrollScreen'

//import PropTypes from 'prop-types';

const deprecated = (item) =>
  ![
    'yeoman',
    'adobe-flash',
    'adobe-air',
    'facebook',
    'action-script-3',
    'corona-sdk-lua',
  ].includes(item.id)

const About = withTags((props) => {
  const [open, toggle] = useToggle()
  const {collection, sectionRef} = props
  const items = collection.filter(deprecated)
    .map(i => {
      return {
        ...i,
        path: '/static_files/img/' + i.path.replace(
          'code',
          'code-sm'
        )
      }
    })
  //const parentRef = useRef();

  useEffect(() => {
    scrollScreen(0, 0)
  })
  return (
    <div>
      <CarouselDialog open={open} toggle={toggle} />
      <div className="container">
        <div className="intro-section">
          <h1>Hello! I'm Alaa El Helw, a French developer</h1>
          <h2>Working for Carrefour and also as a freelancer</h2>
        </div>

        <CssWaterfall delay={50}>
          <div className="about-section animated fadeInUp" >
            <h3>Who are you?</h3>
            <p className="skills-list">
              Born and raised in France,
              I Left my <FlipLink onClick={toggle} style={{padding: '0 5px'}}>hometown</FlipLink> its been a few years and now I'm based in Paris
            </p>
          </div>


          <div className="about-section animated fadeInUp" >
            <h3>How to spell my name ?</h3>
            <p className="skills-list">
              <Highlight style={{padding: '0 5px'}}>Alaa</Highlight> is spelled like "Alé" (phon: ælé) in French which is very close to the ending sound of a famous female singer... <br />
              <Highlight style={{padding: '0 5px'}}>El Helw</Highlight> is spelled like this "El Helu".
            </p>
          </div>


          <div className="about-section animated fadeInUp">
            <h3>What are my tools and programming languages?</h3>
            <p className="skills-list">
              {items.map(item => (<span key={item.id}>
              {item.title + ', '}
            </span>)
              )}...
            </p>
          </div>

          <div className="about-section animated fadeInUp" >
            <h3>What do I look like?</h3>
            <div className="profile fadeIn animated"/>
          </div>


          <div className="about-section  animated fadeInUp" style={{animationDelay: '300ms'}}>
            <h3>Where can I be found?</h3>
            <ul className="list-unstyled social-list">
              <li>
                <span>Email</span><a href="mailto:work@sunny.fr">work@sunny.fr</a>
              </li>
              <li><span>Github</span>
                <a
                  href={'https://github.com/Sunny-fr'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  github.com/Sunny-fr
                </a>
              </li>
              <li><span>NPM</span>
                <a
                  href={'https://www.npmjs.com/~sunny-fr'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  npmjs.com/~sunny-fr
                </a>
              </li>
              <li><span>Docker</span>
                <a
                  href={'https://hub.docker.com/u/sunnyfr'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  hub.docker.com/u/sunnyfr
                </a>
              </li>
              <li><span>Linkedin</span>
                <a
                  href={'https://linkedin.com/in/alaa-el-helw'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  linkedin.com/in/alaa-el-helw
                </a>
              </li>
              <li><span>Facebook</span>
                <a
                  href={'https://facebook.com/Sunnyms'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  facebook.com/Sunnyms
                </a>
              </li>
              <li><span>Twitter</span>
                <a
                  href={'https://twitter.com/itsAley'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  twitter.com/itsAley
                </a>
              </li>
            </ul>
          </div>
        </CssWaterfall>



      </div>
    </div>
  )
})

const AboutPage = ({sectionRef}) => (<About ARCConfig={tagsConfig} sectionRef={sectionRef}/>)

export default AboutPage
