import React from 'react'
import './flip-link.scss'
const FlipLink = ({children, onClick, style}) => {
  return (
    <a className="flip-link" style={style} onClick={onClick}>{children}</a>
  )
}


export default FlipLink
